import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { sanitizeFilenameInUrl } from '../../utils/StringSanitizer';
import KPConfig from '../../KPConfig';
import * as api from './compilationAPI';
import { getGlobalAbortControllerById } from '../../utils/abortController';
import { defaultNodeTerm, sortObjects } from './compilationVariables';
import { nodeTermIsValid, setEditModeAttributes } from './compilationVariables';

const defaultSorting = { 'modified_ts': 'desc' };

const defaultPrintSettings = {
  printCoverPage: false,
  printTableOfContents: false,
  printEmptyNodes: false,
  printNamesAndTimestamps: false,
  printDocumentPaths: false,
  printDocumentTags: false,
  pagebreakOnSection: false,
  showSectionTitle: false,
  showSectionContent: false
};

const initialState = {
  status: 'idle',
  objectIds: [],
  objectsById: {},
  searchTs: 0,
  searchMade: false,
  total: 0,
  q: '',
  folders: [],
  startDate: null,
  endDate: null,
  dateField: 'modified_ts',
  includeSubfolders: true,
  nodeTerms: {},
  nodeTermIndexes: [],
  documentTemplate: null,
  printSettings: defaultPrintSettings,
  title: '',
  createdDocumentId: null,
  objectsSorting: defaultSorting,
  pdfDowndloadUrl: null,
  nodeTermSearchResults: {},
  nodeTermSearchInnerHitsCount: {},
  nodeTermStatus: {},
  criteriaStatus: 'idle',
  criteriaStatusById: {},
  searchCriteriaById: {},
  searchCriteriaIds: [],
  selectedSearchCriteriaId: null,
  newSearchCriteriaTitle: '',
  step: 1,
  searchCriteriaChanged: false
};

export const searchObjects = createAsyncThunk(
  'compilation/searchObjects',
  async (_, { getState }) => {
    const globalAbortController = getGlobalAbortControllerById('compilationSlice.searchObjects');

    const params = {
      q: getState().compilation.q,
      folderIds: getState().compilation.folders.map(f => f.id),
      nodeTerms: JSON.stringify(Object.values(getState().compilation.nodeTerms)),
      startDate: getState().compilation.startDate || '',
      endDate: getState().compilation.endDate || '',
      dateField: getState().compilation.dateField,
      subfolders: getState().compilation.includeSubfolders ? 1 : 0,
    }

    const response = await api.searchObjects(params, globalAbortController);

    return response?.body;
  }
);

export const createCompilation = createAsyncThunk(
  'compilation/createCompilation',
  async (format, { getState }) => {
    const params = {
      object_ids: getState().compilation.objectIds.filter(id => getState().compilation.objectsById[id].selected),
      document_template_id: getState().compilation.documentTemplate.value,
      node_terms: JSON.stringify(Object.values(getState().compilation.nodeTerms)),
      print_settings: getState().compilation.printSettings,
      format: format,
      title: getState().compilation.title,
      sort: getState().compilation.objectsSorting,
    }

    const response = await api.createCompilation(params);

    return response?.body;
  }
);

export const searchWithNodeTerm = createAsyncThunk(
  'compilation/searchWithNodeTerm',
  async (params, { getState }) => {
    const globalAbortController = getGlobalAbortControllerById(params.index);

    const apiParams = {
      q: getState().compilation.q,
      folderIds: getState().compilation.folders.map(f => f.id),
      nodeTerms: JSON.stringify([getState().compilation.nodeTerms[params.index]]),
      startDate: getState().compilation.startDate || '',
      endDate: getState().compilation.endDate || '',
      dateField: getState().compilation.dateField,
      subfolders: getState().compilation.includeSubfolders ? 1 : 0,
      returnInnerHitsCount: true
    }

    const response = await api.searchObjects(apiParams, globalAbortController);

    return response?.body;
  }
);

export const fetchSearchCriteria = createAsyncThunk(
  'compilation/fetchSearchCriteria',
  async () => {
    const response = await api.fetchSearchCriteria();

    return response?.body;
  }
);

/**
 * Create and save new search criteria
 */
export const saveSearchCriteria = createAsyncThunk(
  'compilation/saveSearchCriteria',
  async (_, { getState, dispatch }) => {
    const params = {
      q: getState().compilation.q,
      folder_ids: getState().compilation.folders.map(f => f.id),
      start_date: getState().compilation.startDate || '',
      end_date: getState().compilation.endDate || '',
      date_field: getState().compilation.dateField,
      include_subfolders: getState().compilation.includeSubfolders ? 1 : 0,
      node_terms: setEditModeAttributes(Object.values(getState().compilation.nodeTerms), false),
      title: getState().compilation.newSearchCriteriaTitle,
      print_settings: getState().compilation.printSettings
    }

    const response = await api.saveSearchCriteria(params);

    dispatch(fetchSearchCriteria());

    return response?.body;
  }
);

/**
 * Update existing search criteria
 */
export const updateSearchCriteria = createAsyncThunk(
  'compilation/updateSearchCriteria',
  async (params, { getState, dispatch }) => {
    let data = {};
    let id = null;

    if (params?.title) {
      data.title = params.title;
      id = params.id;
    } else {
      data = {
        q: getState().compilation.q,
        folder_ids: getState().compilation.folders.map(f => f.id),
        start_date: getState().compilation.startDate || '',
        end_date: getState().compilation.endDate || '',
        date_field: getState().compilation.dateField,
        include_subfolders: getState().compilation.includeSubfolders ? 1 : 0,
        node_terms: setEditModeAttributes(Object.values(getState().compilation.nodeTerms), false),
        print_settings: getState().compilation.printSettings
      }

      id = getState().compilation.selectedSearchCriteriaId;
    }

    const response = await api.updateSearchCriteria(id, data);

    // We are just updating title, we can do it without whole fetch
    if (!params?.title && params?.title !== '') {
      dispatch(fetchSearchCriteria());
    }

    return response?.body;
  }
);

export const deleteSearchCriteria = createAsyncThunk(
  'compilation/deleteSearchCriteria',
  async (id) => {
    const response = await api.deleteSearchCriteria(id);

    return response?.body;
  }
);

export const compilationSlice = createSlice({
  name: 'compilation',
  initialState,
  reducers: {
    resetData: () => {
      return initialState;
    },
    startFromScratch: (state) => {
      const newState = { ...initialState };

      // Simulate the case where search criteria are already fetched from the backend
      newState.searchCriteriaIds = state.searchCriteriaIds;
      newState.searchCriteriaById = state.searchCriteriaById;
      newState.criteriaStatus = 'fetchDone';

      if (!newState.searchCriteriaIds.length) {
        newState.step = 2;
      }

      for (const key in newState) {
        state[key] = newState[key];
      }
    },
    addNodeTerm: (state, action) => {
      const type = action.payload;
      const newIndex = state.nodeTermIndexes.length;
      state.nodeTerms[newIndex] = {
        type: type,
        labelText: '',
        valueText: '',
        editMode: true,
        searchGroups: [{
          operator: null,
          searchGroups: [{
            operator: null,
            nodeTerms: [defaultNodeTerm]
          }]
        }]
      };
      state.nodeTermIndexes.push(newIndex);
      state.searchCriteriaChanged = true;
    },
    setNodeTermAttribute: (state, action) => {
      state.nodeTerms[action.payload.index][action.payload.field] = action.payload.value;
    },
    setQ: (state, action) => {
      state.q = action.payload;
    },
    setFolders: (state, action) => {
      state.folders = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setSubfolders: (state, action) => {
      state.includeSubfolders = action.payload;
    },
    setSearchGroupNodeTermAttribute: (state, action) => {
      state
        .nodeTerms[action.payload.index]
        .searchGroups[action.payload.searchGroupIndex]
        .searchGroups[action.payload.innerSearchGroupIndex]
        .nodeTerms[action.payload.nodeTermIndex][action.payload.field] = action.payload.value;
    },
    setSearchGroupOperator: (state, action) => {
      state
        .nodeTerms[action.payload.index]
        .searchGroups[action.payload.searchGroupIndex]
        .searchGroups[action.payload.innerSearchGroupIndex]
        .operator = action.payload.value;
    },
    addNodeTermToSearchGroup: (state, action) => {
      state
        .nodeTerms[action.payload.index]
        .searchGroups[action.payload.searchGroupIndex]
        .searchGroups[action.payload.innerSearchGroupIndex]
        .nodeTerms.push(defaultNodeTerm);
    },
    onOrOperatorClick: (state, action) => {
      state.nodeTerms[action.payload.index].searchGroups[action.payload.searchGroupIndex].operator = "or";
      state.nodeTerms[action.payload.index].searchGroups[action.payload.searchGroupIndex].searchGroups.push({
        operator: null,
        nodeTerms: [defaultNodeTerm],
      });
    },
    deleteNodeTerm: (state, action) => {
      state.nodeTermIndexes = state.nodeTermIndexes.filter(index => index !== action.payload.index);
      delete state.nodeTerms[action.payload.index];
      state.searchCriteriaChanged = true;
    },
    deleteSearchGroupNodeTerm: (state, action) => {
      state.nodeTerms[action.payload.index].searchGroups[action.payload.searchGroupIndex].searchGroups[action.payload.innerSearchGroupIndex].nodeTerms.splice(action.payload.nodeTermIndex, 1);

      if (!state.nodeTerms[action.payload.index].searchGroups[action.payload.searchGroupIndex].searchGroups[action.payload.innerSearchGroupIndex].nodeTerms.length) {
        state.nodeTerms[action.payload.index].searchGroups[action.payload.searchGroupIndex].searchGroups.splice(action.payload.innerSearchGroupIndex, 1);
      }
    },
    setDocumentTemplate: (state, action) => {
      state.documentTemplate = action.payload;
    },
    setObjectsSorting: (state, action) => {
      const sorting = action.payload;
      state.objectsSorting = sorting;
      sortObjects(state, sorting);
    },
    toggleObjectSelected: (state, action) => {
      state.objectsById[action.payload].selected = !state.objectsById[action.payload].selected;
    },
    selectAllObjects: (state) => {
      state.objectIds.forEach(id => state.objectsById[id].selected = true);
    },
    deselectAllObjects: (state) => {
      state.objectIds.forEach(id => state.objectsById[id].selected = false);
    },
    setPrintSettingValue(state, action) {
      state.printSettings[action.payload.key] = action.payload.value;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    setSelectedSearchCriteria(state, action) {
      state.selectedSearchCriteriaId = action.payload;
      const selectedSearchCriteria = state.searchCriteriaById[action.payload];

      state.nodeTerms = JSON.parse(selectedSearchCriteria.node_terms).reduce((acc, nodeTerm, index) => {
        acc[index] = nodeTerm;
        return acc;
      }, {});

      state.nodeTermIndexes = Object.keys(state.nodeTerms);

      state.q = selectedSearchCriteria.q;
      state.folders = selectedSearchCriteria.folders;
      state.startDate = selectedSearchCriteria.start_date;
      state.endDate = selectedSearchCriteria.end_date;
      state.dateField = selectedSearchCriteria.date_field;
      state.includeSubfolders = selectedSearchCriteria.include_subfolders;
      state.printSettings = JSON.parse(selectedSearchCriteria.print_settings);

      state.step = 2;
    },
    setNewSearchCriteriaTitle(state, action) {
      state.newSearchCriteriaTitle = action.payload;
    },
    setSearchCriteriaEditMode(state, action) {
      state.searchCriteriaById[action.payload.id].editMode = action.payload.value;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    setDateField(state, action) {
      state.dateField = action.payload;
    },
    setSearchCriteriaChanged(state, action) {
      state.searchCriteriaChanged = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchObjects.pending, (state) => {
        state.status = 'searching';
        state.searchCriteriaChanged = false;
      })
      .addCase(searchObjects.fulfilled, (state, action) => {
        if (!action?.payload) {
          // Request was aborted
          return;
        }
        state.status = 'idle';
        state.objectsById = action.payload.results.reduce((acc, o) => {
          o.selected = true;
          acc[o.id] = o;
          return acc;
        }, {});

        sortObjects(state, state.objectsSorting);

        state.searchMade = true;
      })
      .addCase(searchObjects.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createCompilation.pending, (state) => {
        state.status = 'creating';
        state.createdDocumentId = null;
        state.pdfDowndloadUrl = null;
      })
      .addCase(createCompilation.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload.object) {
          state.createdDocumentId = action.payload.object.id;
        }
        if (action.payload.status_id) {
          const fileName = sanitizeFilenameInUrl(state.title + '.pdf')
          state.pdfDowndloadUrl = `${KPConfig.backendUrl}/pdf/download/${action.payload.status_id}/${fileName}`;
        }
      })
      .addCase(createCompilation.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(searchWithNodeTerm.pending, (state, action) => {
        state.nodeTermStatus[action.meta.arg.index] = 'searching';
      })
      .addCase(searchWithNodeTerm.fulfilled, (state, action) => {
        if (!action?.payload) {
          // Request was aborted
          return;
        }
        state.nodeTermStatus[action.meta.arg.index] = 'idle';
        state.nodeTermSearchResults[action.meta.arg.index] = action.payload.results.reduce((acc, o) => {
          acc[o.id] = o;
          return acc;
        }, {});
        state.nodeTermSearchInnerHitsCount[action.meta.arg.index] = action.payload.innerHitsCount;
      })
      .addCase(searchWithNodeTerm.rejected, (state, action) => {
        state.nodeTermStatus[action.meta.arg.index] = 'error';
      })
      .addCase(fetchSearchCriteria.pending, (state) => {
        state.criteriaStatus = 'loading';
      })
      .addCase(fetchSearchCriteria.fulfilled, (state, action) => {
        state.searchCriteriaIds = action.payload.map(c => c.id);
        state.searchCriteriaById = action.payload.reduce((acc, c) => {
          acc[c.id] = c;
          return acc;
        }, {});
        state.criteriaStatus = 'fetchDone';
      })
      .addCase(fetchSearchCriteria.rejected, (state) => {
        state.criteriaStatus = 'error';
      })
      .addCase(deleteSearchCriteria.pending, (state) => {
        state.criteriaStatus = 'deleting';
      })
      .addCase(deleteSearchCriteria.fulfilled, (state, action) => {
        state.criteriaStatus = 'idle';
        state.searchCriteriaIds = state.searchCriteriaIds.filter(id => id !== action.meta.arg);
        delete state.searchCriteriaById[action.meta.arg];
      })
      .addCase(deleteSearchCriteria.rejected, (state) => {
        state.criteriaStatus = 'error';
      })
      .addCase(updateSearchCriteria.pending, (state, action) => {
        if (action.meta.arg?.id) {
          state.criteriaStatusById[action.meta.arg.id] = 'updating';
        } else {
          state.criteriaStatus = 'updating';
        }
      })
      .addCase(updateSearchCriteria.fulfilled, (state, action) => {
        if (action?.meta?.arg?.id) {
          state.criteriaStatusById[action.meta.arg?.id] = 'idle';
        } else {
          state.criteriaStatus = 'idle';
        }

        if (action.meta.arg?.title || action.meta.arg?.title === '') {
          state.searchCriteriaById[action.meta.arg?.id].title = action.meta.arg?.title;
        }
      })
      .addCase(updateSearchCriteria.rejected, (state, action) => {
        if (action?.meta?.arg?.id) {
          state.criteriaStatusById[action.meta.arg?.id] = 'error';
        } else {
          state.criteriaStatus = 'error';
        }
      })
      .addCase(saveSearchCriteria.pending, (state) => {
        state.criteriaStatus = 'saving';
      })
      .addCase(saveSearchCriteria.fulfilled, (state, action) => {
        state.criteriaStatus = 'idle';
        state.selectedSearchCriteriaId = Number(action.payload.id);
      })
      .addCase(saveSearchCriteria.rejected, (state) => {
        state.criteriaStatus = 'error';
      });
  },
});

export const {
  startFromScratch,
  resetData,
  addNodeTerm,
  setNodeTermAttribute,
  setQ,
  setFolders,
  setStartDate,
  setEndDate,
  setSubfolders,
  setSearchGroupNodeTermAttribute,
  setSearchGroupOperator,
  addNodeTermToSearchGroup,
  onOrOperatorClick,
  deleteNodeTerm,
  deleteSearchGroupNodeTerm,
  setDocumentTemplate,
  setObjectsSorting,
  toggleObjectSelected,
  selectAllObjects,
  deselectAllObjects,
  setPrintSettingValue,
  setTitle,
  setSelectedSearchCriteria,
  setNewSearchCriteriaTitle,
  setSearchCriteriaEditMode,
  setStep,
  setDateField,
  setSearchCriteriaChanged
} = compilationSlice.actions;

export const selectQ = (state) => state.compilation.q;
export const selectFolders = (state) => state.compilation.folders;
export const selectStartDate = (state) => state.compilation.startDate;
export const selectEndDate = (state) => state.compilation.endDate;
export const selectSubfolders = (state) => state.compilation.includeSubfolders;
export const selectNodeTermIndexes = (state) => state.compilation.nodeTermIndexes;
export const selectObjectIds = (state) => state.compilation.objectIds;
export const selectObjectById = (state, id) => state.compilation.objectsById[id];
export const selectSelectedObjectIds = (state) => state.compilation.objectIds.filter(id => state.compilation.objectsById[id].selected);
export const selectSearchTs = (state) => state.compilation.searchTs;
export const selectSearchMade = (state) => state.compilation.searchMade;
export const selectStatus = (state) => state.compilation.status;
export const selectDocumentTemplate = (state) => state.compilation.documentTemplate;
export const selectPrintSettings = (state) => state.compilation.printSettings;
export const selectTitle = (state) => state.compilation.title;
export const selectCreatedDocumentId = (state) => state.compilation.createdDocumentId;
export const selectObjectsSorting = (state) => state.compilation.objectsSorting;
export const selectCompilationPDFUrl = (state) => state.compilation.pdfDowndloadUrl;
export const selectNodeTerm = (state, index) => state.compilation.nodeTerms[index];
export const selectNodeTermAttribute = (state, index, field) => state.compilation.nodeTerms[index]?.[field];
export const selectNodeTermSearchResults = (state, index) => state.compilation.nodeTermSearchResults[index];
export const selectNodeTermSearchInnerHitsCount = (state, index) => state.compilation.nodeTermSearchInnerHitsCount[index];
export const selectNodeTermStatus = (state, index) => state.compilation.nodeTermStatus[index];
export const selectCriteriaStatus = (state) => state.compilation.criteriaStatus;
export const selectSearchCriteriaIds = (state) => state.compilation.searchCriteriaIds;
export const selectSearchCriteriaByIdAll = (state) => state.compilation.searchCriteriaById;
export const selectSearchCriteriaById = (state, id) => state.compilation.searchCriteriaById[id];
export const selectSelectedSearchCriteriaId = (state) => state.compilation.selectedSearchCriteriaId;
export const selectNewSearchCriteriaTitle = (state) => state.compilation.newSearchCriteriaTitle;
export const selectSearchCriteriaEditModeById = (state, id) => state.compilation.searchCriteriaById[id]?.editMode;
export const selectStatusById = (state, id) => state.compilation.criteriaStatusById[id];
export const selectDateField = (state) => state.compilation.dateField;

export const selectAnySearchFieldChanged = createSelector(
  [selectQ, selectFolders, selectStartDate, selectEndDate, selectSubfolders, selectDateField],
  () => {
    return [];  // Array always makes useEffect to run and re-render happen. We want that.
  }
);

export const selectStep = (state) => state.compilation.step;
export const selectHasAnyValidNodeTerms = (state) => {
  return Object.values(state.compilation.nodeTerms).some(nodeTerm => {
    return nodeTermIsValid(nodeTerm);
  });
}

export const selectSearchCriteriaChanged = (state) => state.compilation.searchCriteriaChanged;
export const selectSearchButtonTypeIsSuccess = createSelector(
  [selectSearchCriteriaChanged, selectSearchMade, selectStatus],
  (searchCriteriaChanged, searchMade, status) => {
    return (!searchMade || searchCriteriaChanged) && status !== 'searching';
  }
);

export const selectNodeTermsHaveSection = createSelector(
  [state => state.compilation.nodeTerms],
  (nodeTerms) => {
    return Object.values(nodeTerms).some(nodeTerm => {
      return nodeTerm.type === 'section';
    });
  }
);

export default compilationSlice.reducer;
