import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './authAPI';

//import { AsYouType } from 'libphonenumber-js'

const defaultLocale = () => {
  const browserLocale = (navigator.language || navigator.userLanguage).toLowerCase().split(/[_-]+/)[0];
  const params = new URLSearchParams(window.location.search);
  if (params?.get('lang')) {
    return params.get('lang');
  }
  return browserLocale;
}

const signupLocale = () => {
  const params = new URLSearchParams(window.location.search);
  if (params?.get('lang')) {
    return params.get('lang');
  }
  return 'fi';
}

const initialState = {
  status: 'idle',
  isAuthenticated: false,
  initialAccountInformation: {},
  initialAuthData: {},
  data: { locale: defaultLocale(), signupLocale: signupLocale() },
  saveEnabled: false
};

export const getAuth = createAsyncThunk(
  'auth/get',
  async () => {
    const response = await api.get();

    if (!response.body.locale) {
      response.body.locale = defaultLocale();
    }

    return response.body;
  },
  {
    condition: (opts) => {
      // This will be run before the pending reducer
      return !opts?.skipFetch;
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.data.locale = action.payload;
      state.data.signupLocale = action.payload;
    },
    setFirstName: (state, action) => {
      state.data.first_name = action.payload;
    },
    setLastName: (state, action) => {
      state.data.last_name = action.payload;
    },
    setCompanyName: (state, action) => {
      state.data.company_name = action.payload;
    },
    setSharedToMeEnabled: (state,action) => {
      state.data.shared_to_me_enabled = action.payload;
    },
    setSignatureAlign: (state, action) => {
      state.data.signature.align = action.payload;
    },
    setSignatureText: (state, action) => {
      state.data.signature.text = action.payload;
    },
    setSignatureUseLogo: (state, action) => {
      state.data.signature.use_logo = action.payload;
    },
    setUserSignature: (state, action) => {
      state.data.user_signature = action.payload;
    },
    setPhone: (state, action) => {
      /**
       * We dont know country and cant use this kind of thing.
       * And phone number doesn't even depend on user country. User should select his phone-countrycode
       *const asYouType = new AsYouType('FI');
       * asYouType.input(action.payload);
       *
       * state.data.phone = asYouType.getNumberValue();
       */

      // Just use this kind of light autocomplete and guiding
      let value = action.payload;
      if (value?.length === 1 && value?.charAt(0) === '0') {
        value = '+358';
      }

      state.data.phone = value;
    },
    setUiTheme: (state, action) => {
      state.data.ui_theme = action.payload;
      state.saveEnabled = true;
    },
    setSaveEnabled: (state, action) => {
      state.saveEnabled = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuth.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAuth.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isAuthenticated = true;
        state.data = action.payload;
        state.initialAuthData = action.payload;
      })
      .addCase(getAuth.rejected, (state) => {
        state.status = 'idle';
        state.isAuthenticated = false;
        state.data = { locale: defaultLocale() };

        // Propably user is just not logged in, redirect to /login
        const pathName = window.location;
        window.location.replace(pathName.origin + '/login');
      })
  }
});

export const {
  setLocale,
  setFirstName,
  setLastName,
  setCompanyName,
  setSharedToMeEnabled,
  setSignatureAlign,
  setSignatureText,
  setPhone,
  setSignatureUseLogo,
  setUserSignature,
  setUiTheme,
  setSaveEnabled
} = authSlice.actions;

export const selectLocale = (state, baseName) => baseName === 'start' ? state.auth.data.signupLocale : state.auth.data.locale;
export const selectCompanyName = (state) => {
  return state.auth.data.company_name === state.auth.data.email ? '' : state.auth.data.company_name;
}
export const selectCompanyId = (state) => state.auth.data.company_id;
export const selectEmail = (state) => state.auth.data.email;
export const selectPhone = (state) => state.auth.data.phone || '';
export const selectFirstName = (state) => state.auth.data.first_name;
export const selectLastName = (state) => state.auth.data.last_name;
export const selectUserCreatedTs = (state) => state.auth.data.created_ts;
export const selectAuthData = (state) => state.auth.data;
export const selectUserSignature = (state) => state.auth.data.user_signature;
export const selectSharedToMeEnabled = (state) => state.auth.data.shared_to_me_enabled;
export const selectSignatureData = (state) => state.auth.data.signature;
export const selectUserId = (state) => state.auth.data.user_id;
export const selectIsAdmin = (state) => state.auth.data.rights?.admin;
export const selectIsEditor = (state) => state.auth.data.rights?.editor;
export const selectWorkspaceCode = (state) => state.auth.data.productFruitsWorkspaceCode;
export const selectHasBillingRights = (state) => state.auth.data.rights?.billing;
export const selectSignupRouteIsQuickTrial = (state) => state.auth.data.signup_route === 'quick-trial';
export const selectSignupRouteIsInvitation = (state) => state.auth.data.signup_route === 'invitation';
export const selectPasswordMissing = (state) => state.auth.data.passwordMissing;
export const selectEmailVerificationStatus = (state) => state.auth.data.emailVerificationStatus;
export const selectStatus = (state) => state.auth.status;
export const selectInitialAuthData = (state) => state.auth.initialAuthData
export const selectUiTheme = (state) => state.auth.data.ui_theme || "yellow-default";
export const selectSaveEnabled = (state) => state.auth.saveEnabled;
export const selectIsIndividualUser = (state) => state.auth.data.individual;
export const selectSuperadmin = (state) => state.auth.data.superadmin;
export const selectGroupadmin = (state) => state.auth.data.groupadmin;
export const selectCompanies = (state) => state.auth.data.companies || [];
export const selectIsExternalEnvironment = (state) => {
  return state.auth.data.userRealCompanyId && state.auth.data.company_id !== state.auth.data.userRealCompanyId ? true : false
};
export const selectUserSetting = (state, settingName) => {
  if (!settingName || typeof settingName !== "string") {
    throw new Error("Invalid settingName argument");
  }

  const { settings } = state.auth.data;
  if (!settings) {
    return null;
  }

  const matchingSetting = settings.find(setting => setting.name === settingName);
  if (!matchingSetting) {
    return null;
  }

  if (matchingSetting.value) {
    return matchingSetting.value;
  } else {
    return  [];
  }
};

export default authSlice.reducer;
