import { apiCall } from '../../utils/APIFunctions';
import KPConfig from '../../KPConfig';

export function fetchSignupTemplates(locale) {
  return apiCall(`${KPConfig.backendUrl}/start/templates/${locale}`, "GET");
}

export function createAccount(params) {
  return apiCall(`${KPConfig.backendUrl}/quick-trial`, "POST", JSON.stringify(params));
}

export function login(params) {
  return apiCall(`${KPConfig.backendUrl}/clientlogin`, "POST", JSON.stringify(params));
}

export function newBeLogin(params) {
  if (KPConfig.newBackendUrl) {
    return apiCall(
      `${KPConfig.newBackendUrl}/api/n1/tokenLogin`,
      "POST",
      JSON.stringify(params),
      null,
      {skipErrorHandling: true}
    );
  }
}

export function newBeLogout(params) {
  if (KPConfig.newBackendUrl) {
    return apiCall(
      `${KPConfig.newBackendUrl}/api/n1/logout`,
      "POST",
      JSON.stringify(params),
      null,
      {skipErrorHandling: true}
    );
  }
}

export function sendPasswordReset(params) {
  return apiCall(`${KPConfig.backendUrl}/password-reset`, "POST", JSON.stringify(params));
}

export function sendEmailVerificationEmail(email) {
  return apiCall(`${KPConfig.backendUrl}/start/sendEmailVerificationEmail`, "POST", JSON.stringify({email: email}));
}

export function sendLoginEmail(email) {
  return apiCall(`${KPConfig.backendUrl}/sendLoginEmail`, "POST", JSON.stringify({email: email}));
}

export function inviteLogin(params) {
  return apiCall(`${KPConfig.backendUrl}/inviteLogin?id=${params.inviteId}&invite=${params.invite}&objectId=${params.objectId}`, "GET");
}

export function validatePasswordResetCode(code) {
  return apiCall(`${KPConfig.backendUrl}/passwordResetCode?code=${code}`, "GET");
}
export function passwordReset(params) {
  return apiCall(`${KPConfig.backendUrl}/passwordReset`, "POST", JSON.stringify(params));
}

export function getNonce() {
  return apiCall(`${KPConfig.backendUrl}/api/v2/login-nonce`, "GET");
}

export function respondToChallenge(params) {
  return apiCall(`${KPConfig.backendUrl}/api/v2/login-challenge`, "POST", JSON.stringify(params));
}

export function getObjectDownloadTokenStatus(params) {
  return apiCall(`${KPConfig.backendUrl}/objectDownloadTokenStatus?id=${params.id}&token=${params.token}`, "GET");
}